import classNames from 'classnames'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setCurrency } from '../actions'
import { useComponentDidMount } from '../hooks/useComponentDidMount'
import { Currency } from '../model'
import { State } from '../reducers'
import amplitudeService from '../services/amplitudeService'
import { getUnWrappedNativeTokenSymbol } from '../utils'

const SwitchCurrency: FC = () => {
  const reduxDispatch = useDispatch()
  const { currency } = useSelector((state: State) => state)
  const currentToken = useSelector((state: State) => state.tokens.currentToken)
  useComponentDidMount(() => {
    reduxDispatch(setCurrency('USD'))
  })

  if (!currentToken) {
    return null
  }
  const setCurrencyHandler = (event: React.MouseEvent<HTMLButtonElement>): void => {
    const current = event.currentTarget
    const newCurrency = current.dataset.currency

    if (!newCurrency) {
      return
    }

    if (newCurrency !== currency) {
      amplitudeService.baseCurrencySwitched(newCurrency)
    }

    reduxDispatch(setCurrency(newCurrency as Currency))
  }

  return (
    <div className="switch-currency">
      <button
        className={classNames('switch-currency__option', {
          active: currency.toLowerCase() === 'usd',
        })}
        onClick={setCurrencyHandler}
        data-currency="USD">
        USD
      </button>
      <button
        className={classNames('switch-currency__option', {
          active: currency.toLowerCase() !== 'usd',
        })}
        onClick={setCurrencyHandler}
        data-currency="ETH">
        {getUnWrappedNativeTokenSymbol(currentToken.network)}
      </button>
    </div>
  )
}

export default SwitchCurrency
