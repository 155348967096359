import { modelUtils } from '@telekomconsalting/dex-guru-model'
import { ScanIcon } from '@telekomconsalting/react-dexguru-wallet'
import { ReactComponent as Cross } from 'app-images/icons/cross.svg'
import { ReactComponent as AnimatedArrow } from 'app-images/icons/direction-down.svg'
import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import {
  financialFormat,
  financialFormatLowValueHighPrecision,
} from '../helpers/priceAndAmountHelpers'
import { NetworkConfigV3, TokenV3 } from '../model'
import { State } from '../reducers'
import {
  getExplorerTokenUrl,
  getInlineNetworkIconStyle,
  getMarketDisplayName,
  getTokenAddress,
  isTitleNaN,
} from '../utils'
import IconTokenWrapper from './IconTokenWrapper'
import Slide from './Slide'

const AnimatedDrop = React.lazy(() => import(/* webpackPrefetch: true */ './AnimatedDrop'))

interface SwapSummaryProps {
  tokenFrom: TokenV3
  tokenTo: TokenV3
  tokenNetwork: string
  fromAmount: BigNumber
  toAmount: BigNumber
  fromAmountSelectedCurrency: BigNumber
  toAmountSelectedCurrency: BigNumber
  delta?: number
  readonly?: boolean
  failed?: boolean
  isLimitOrder?: boolean
}

const SwapSummaryAmount: FC<{
  title: string
  current: BigNumber
  delta?: number
}> = ({ title, current, delta }) => {
  return (
    <div className="amount">
      <span className="title">{title}</span>
      <span className="sign">~$</span>
      <Slide className="value" value={financialFormat(current || 0, { decimals: 2 })} />
      {delta && (
        <span
          className={classNames(
            'value-delta swap-verify__delta',
            { 'swap-verify__delta--up': delta > 0 },
            { 'swap-verify__delta--down': delta <= 0 }
          )}>
          ({delta?.toFixed(2)}%)
        </span>
      )}
    </div>
  )
}

const SwapSummary = ({
  tokenFrom,
  tokenTo,
  tokenNetwork,
  fromAmount,
  toAmount,
  fromAmountSelectedCurrency,
  toAmountSelectedCurrency,
  delta,
  readonly,
  failed,
  isLimitOrder,
}: SwapSummaryProps): React.ReactElement => {
  const networksConfig: NetworkConfigV3[] = useSelector((state: State) => state.networksConfig.data)
  const networkConfig = networksConfig.find((x) => x.name === tokenFrom?.network)
  const blockExplorerUrlTokenFrom =
    tokenNetwork && tokenFrom && getExplorerTokenUrl(tokenNetwork, getTokenAddress(tokenFrom))
  const blockExplorerUrlTokenTo =
    tokenNetwork && tokenTo && getExplorerTokenUrl(tokenNetwork, getTokenAddress(tokenTo))

  const styleIcon = getInlineNetworkIconStyle(tokenNetwork, networkConfig)

  return (
    <div className={classNames('card swap-summary', { failed })}>
      <div className="swap-summary__row">
        {isLimitOrder && (
          <div className="swap-summary__comment swap-summary__comment--from">You Pay</div>
        )}
        <div className="swap-summary__token">
          <div
            className={classNames(
              'token-ico',
              'token-ico--md',
              tokenNetwork ? `token-ico--network-${tokenNetwork}` : ''
            )}
            style={styleIcon}>
            <IconTokenWrapper
              logoURI={tokenFrom.logoURI}
              symbols={tokenFrom.symbols}
              className="token-ico__image"
            />
          </div>
          <span className="token-name">{getMarketDisplayName(tokenFrom)}</span>
          {networkConfig && (
            <span
              className="network-name"
              style={
                {
                  '--dexguru-network-color': networkConfig.color ? networkConfig.color : undefined,
                } as React.CSSProperties
              }>
              {modelUtils.normalizeNetworkName(networkConfig.name)}
            </span>
          )}
        </div>
        {fromAmount !== undefined && (
          <div className="swap-summary__amount" title={isTitleNaN(fromAmount.toString())}>
            {financialFormat(fromAmount.toFixed(2), { decimals: 2 })}
          </div>
        )}
      </div>
      <div className="swap-summary__row">
        <div className="swap-summary__explorer explorer">
          {tokenFrom && (
            <a
              className="explorer__link"
              href={blockExplorerUrlTokenFrom}
              target="_blank"
              rel="noopener noreferrer">
              {tokenNetwork && networkConfig && (
                <ScanIcon className="explorer__icon" networkConfig={networkConfig} />
              )}
              <span className="explorer__name">{tokenFrom?.name}</span>
            </a>
          )}
        </div>
        {fromAmountSelectedCurrency?.gt(0) && (
          <div
            className="swap-summary__price"
            title={isTitleNaN(fromAmountSelectedCurrency?.toString())}>
            <div className="swap-summary__value">
              <SwapSummaryAmount title="Est. value:" current={fromAmountSelectedCurrency} />
            </div>
          </div>
        )}
        {!readonly && fromAmountSelectedCurrency.eq(0) && <AnimatedDrop isCost={false} />}
      </div>

      <div className="swap-summary__row swap-summary__row--separator">
        <div className="swap-summary__direction">
          {!failed && <AnimatedArrow />}
          {failed && <Cross className="swap-summary__failed" />}
        </div>
      </div>

      <div className="swap-summary__row">
        {isLimitOrder && (
          <div className="swap-summary__comment swap-summary__comment--to">You Receive</div>
        )}
        <div className="swap-summary__token">
          <div
            className={classNames(
              'token-ico',
              'token-ico--md',
              tokenNetwork ? `token-ico--network-${tokenNetwork}` : ''
            )}
            style={styleIcon}>
            <IconTokenWrapper
              logoURI={tokenTo.logoURI}
              symbols={tokenTo.symbols}
              className="token-ico__image"
            />
          </div>
          <span className="token-name">{getMarketDisplayName(tokenTo)}</span>
          {networkConfig && (
            <span
              className="network-name"
              style={
                {
                  '--dexguru-network-color': networkConfig.color ? networkConfig.color : null,
                } as React.CSSProperties
              }>
              {modelUtils.normalizeNetworkName(networkConfig.name)}
            </span>
          )}
        </div>
        {toAmount !== undefined && (
          <div className="swap-summary__amount" title={isTitleNaN(toAmount.toString())}>
            <Slide value={financialFormatLowValueHighPrecision(toAmount, { decimals: 7 })} />
          </div>
        )}
      </div>

      <div className="swap-summary__row">
        <div className="swap-summary__explorer explorer">
          {tokenTo && networkConfig && (
            <a
              className="explorer__link"
              href={blockExplorerUrlTokenTo}
              target="_blank"
              rel="noopener noreferrer">
              {tokenNetwork && (
                <ScanIcon className="explorer__icon" networkConfig={networkConfig} />
              )}
              <span className="explorer__name">{tokenTo?.name}</span>
            </a>
          )}
        </div>
        {toAmountSelectedCurrency !== undefined && toAmountSelectedCurrency.gt(0) && (
          <div
            className="swap-summary__price"
            title={isTitleNaN(toAmountSelectedCurrency?.toString())}>
            <div className="swap-summary__value">
              <SwapSummaryAmount
                title="Est. value:"
                current={toAmountSelectedCurrency}
                delta={isLimitOrder ? undefined : delta}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SwapSummary
