import { ReactElement } from 'react'

import switchNetwork from '../../helpers/switchNetwork'
import { getInlineNetworkStyle, getNetworkConfig } from '../../utils'

interface SwitchNetworkProps {
  errorMsg?: string
  network: string
}

const SwitchNetwork = ({ errorMsg, network }: SwitchNetworkProps): ReactElement => {
  const networkConfig = getNetworkConfig(network)
  const styleNetwork = getInlineNetworkStyle(networkConfig)

  const handleSwitchNetwork = (): void => {
    switchNetwork(network)
  }

  return (
    <div className="switch-network" onClick={handleSwitchNetwork}>
      {errorMsg && (
        <div className="switch-network__content">
          Click here to switch to&nbsp;
          <span className="network" style={styleNetwork}>
            {networkConfig.description.split(' ')[0]}
          </span>
          &nbsp;and enable trading
        </div>
      )}
    </div>
  )
}

export default SwitchNetwork
